import { CountUp } from "countup.js";

const componentCounterStart = () => {
	const countYear = document.querySelector("#year");

	if (countYear) {
        const countOptions = {
            separator: "",
            duration: 4,
        };
    
        const countUp = new CountUp(countYear, 2023, countOptions);
        countUp.start();
	}
};

export default componentCounterStart;
